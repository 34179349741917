/* ---------- Rider CSS ---------*/
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.rider {
}
/* icons color code #f8ef18 */
.lato {
  font-family: "Lato", sans-serif;
}
.bg-green-gradent {
  background: linear-gradient(to bottom, #091b1c, #010e11);
}
.bg-theme-black {
  background-color: #2d2d2d;
  /* background: linear-gradient(to bottom, #091b1c, #010e11); */
}
.text-theme-black {
  color: #2d2d2d;
}
.bg-theme-yellow {
  background-color: #f6d13a;
}
.w-25 {
  width: 110px;
  transition: 0.4s ease-in-out;
}
.w-25:hover {
  /* box-shadow: 0 0 3px cyan, 0 0 15px cyan, 0 0 50px cyan, 0 0 10px cyan,
    0 0 60px cyan; */
  cursor: pointer;
  box-shadow: 0 0 3px #f8ef18, 0 0 5px #f81818, 0 0 15px #287f46,
    0 0 10px #0022ff, 0 0 20px #ffffff;
}
.w-150 {
  width: 150px;
}
.text-theme-yellow {
  color: #f6d13a;
}
.icon-theme {
  width: 20px;
  display: inline-block;
}
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #000;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

/* Login.css */
.loginForm {
  width: 300px;
  margin: 0 auto;
  /* padding: 20px 0px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

input.radio-check[type="radio"]:checked + label {
  background-color: #f6d13a;
}

input.radio-check[type="radio"]:hover + label {
  background-color: #f6d13a;
}

.hide {
  display: none;
}
.font-11 {
  font-size: 11px;
}

.pv-logo {
  text-align: center;
  margin: 35px 0px;
}
.pv-logo img {
  width: 75%;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  /* margin-bottom: 20px; */
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: black
    url('data:image/svg+xml;utf8,<svg fill="white" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>')
    no-repeat right 0.75rem center;
  padding-right: 2.5rem;
}

.App {
  font-family: sans-serif;
  text-align: center;
  min-height: 700px;
  background-color: paleturquoise;
  margin: 0;
  /* padding: 20px; */
  width: 100%;
  background-color: #2d2d2d;
}

/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open + Sans:300, 400, 700);
@import url(https://fonts.googleapis.com/icon?family=Material + Icons);
/* ---------- Rider CSS ---------*/
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.lato {
  font-family: "Lato", sans-serif;
}

.bg-theme-black {
  background-color: #2d2d2d;
}
.text-theme-black {
  color: #2d2d2d;
}
.bg-theme-yellow {
  background-color: #f6d13a;
}
.text-theme-yellow {
  color: #f6d13a;
}
.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

/* :root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content {
  text-align: center;
} */

.star {
  font-size: 2rem;
  cursor: pointer;
}

.star.filled {
  color: gold;
}

.close-button {
  margin-top: 20px;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

/* GENERAL */
/* 
* {
  box-sizing: border-box;
} */

/* body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
} */

/* header {
  display: block;
  width: 80%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
} */

/* header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
} */

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  /*transform: scale(1.75);*/
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 4em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}
.calendar .body .today {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
  border-image-slice: 1;
}
.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

/* .calendar .body .row:last-child {
    border-bottom: none;
  } */

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

/*New Design CSs will be here*/
.ride-ui {
  position: relative;
  border: 2px solid #000;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
}
.ride-ui .callender-info-box {
  background: #fff;
  margin: 0px 25px;
  border-radius: 40px;
  border: 2px solid #000;
  position: relative;
  top: -21px;
}
.ride-ui .callender-info-box div {
  width: 130px;
  margin: 0px 0px;
  padding: 10px 0px;
  font-weight: 800;
  font-size: 11px;
}
.ride-ui .callender-info-box div.day {
  text-align: left;
  padding-left: 20px;
}
.ride-ui .callender-info-box div.month {
  position: absolute;
  top: 0px;
  height: 48px;
  border-radius: 0px 0px 20px 24px;
  width: 115px;
  font-size: 14px;
}
.ride-ui .callender-info-box div.time {
  text-align: right;
  margin-right: 0px;
  padding-right: 6px;
}
.ride-ui .ride-imge-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: relative;
  margin-bottom: -45px;
  border-radius: 16px 16px 0px 0px;
  padding: 10px 0px;
}
.ride-ui .ride-imge-overlay h2 span:nth-child(1) {
}
.ride-ui .ride-info-box {
  padding: 20px;
  border: 2px solid #000;
  /* margin: 0px 20px; */
}
.ride-ui .ride-info-box h2 {
  font-weight: 800;
  font-size: 14px;
}
.ride-info-box div:nth-child(1) div:nth-child(1) {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.ride-info-box div:nth-child(1) div:nth-child(2) {
  border-bottom: 1px solid #000;
}

.ride-info-box div:nth-child(1) div {
  width: 100%;
}
.ride-info-box div:nth-child(1) h3 {
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
  font-size: 14px;
}
.ride-info-box div:nth-child(1) p {
  font-style: italic;
  font-size: 14px;
}
.ride-info-box div:nth-child(2) {
  /* border-bottom: 1px solid #000; */
  /* border-bottom: 1px solid #000; */
}

.ride-info-box div:nth-child(2) div:nth-child(1) {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.ride-info-box div:nth-child(2) div:nth-child(2) {
  border-bottom: 1px solid #000;
}
.ride-info-box div:nth-child(2) div {
  width: 100%;
}
.ride-info-box div:nth-child(2) h3 {
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
  font-size: 14px;
}
.ride-info-box div:nth-child(2) p {
  font-style: italic;
  font-size: 14px;
}
.rid-action-box button {
  background-image: linear-gradient(
    to right,
    #f09819 0%,
    #edde5d 51%,
    #f09819 100%
  );
  font-size: 16px;
  text-transform: capitalize;
  font-style: italic;
  margin: 10px;
  padding: 11px 28px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  /* color: white; */
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 50px;
  display: block;
  margin: 0 auto;
  font-weight: 800;
  width: 200px;
}

.rid-action-box button img {
  width: 30px;
  margin-right: 5px;
  display: inline-block;
}
.rid-action-box button:hover {
  background-position: right center; /* change the direction of the change here */
  /* color: #fff; */
  text-decoration: none;
}
.rid-action-box button span {
  color: #000;
  position: relative;
  top: 3px;
}
